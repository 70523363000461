import styled from "styled-components";
import React from "react";

export const ScDownloadSvg = styled.svg.attrs(() => ({
  xmlns: "http://www.w3.org/2000/svg",

  viewBox: "0 0 48 48",
}))`
  cursor: pointer;
  height: ${({ size }) => (size ? size : "48px")};
  width: ${({ size }) => (size ? size : "48px")};
  margin: 0 !important;

  &:hover {
    path {
      fill: ${({ theme, color }) =>
        color ? color : theme.colors.primary.hover};
    }
  }
  path {
    fill: ${({ theme, color }) => (color ? color : theme.colors.primary.main)};
    line-height: normal;
  }
`;

export const ScDownloadIcon = props => {
  return (
    <ScDownloadSvg {...props}>
      <path
        fill="#000"
        d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z"
      />
    </ScDownloadSvg>
  );
};
