import styled, { css } from "styled-components";
import React from "react";
import PropTypes from "prop-types";

export const ScListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px;
  ${({ width }) => {
    width &&
      css`
        width: ${width};
      `;
  }}
`;

export const ScListItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
`;
