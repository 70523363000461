import { Link, navigate, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { FirebaseContext } from "./Firebase"
import styled from "styled-components"
import NavBar from "./common/NavBar"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const LogoutLink = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const ScHeaderImage = styled(Img)`
  max-width: 1600px;
`

const Header = ({ siteTitle }) => {
  const headerImg = useStaticQuery(graphql`
    {
      fileName: file(relativePath: { eq: "header_graphic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  console.log("TEST", headerImg.fileName.childImageSharp.fluid.src)

  const { firebase, user } = useContext(FirebaseContext)
  console.log("user", user)

  function handleLogoutClick() {
    firebase.logout().then(() => navigate("/login"))
  }

  return (
    <header>
      <ScHeaderImage fluid={headerImg.fileName.childImageSharp.fluid} />
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <div>
          {user && user.email && (
            <div>
              Hello, {user.username || user.email}
              <LogoutLink onClick={handleLogoutClick}>Logout</LogoutLink>
              <div>
                {user.isAdmin && (
                  <div>
                    <Link to="/add-author">Add Author</Link>
                    <Link to="/add-book">Add Book</Link>
                  </div>
                )}
              </div>
            </div>
          )}{" "}
          {!user ||
            (!user.email && (
              <div>
                <Link to="/login">Login</Link>
                <Link to="/register">Register</Link>
              </div>
            ))}
        </div>
      </div> */}
      <NavBar></NavBar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
