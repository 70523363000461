import React, { Component } from "react";
import styled from "styled-components";

const ScSvg = styled.svg`
  width: 20px;
  height: 20px;

  cursor: pointer;
  circle {
    fill: white;
  }
  #Oval {
    fill: #a9a9a9;
  }
  #Path {
    fill: white;
  }
`;

export const ScInfoIcon = props => {
  return (
    <ScSvg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"

      idth="152px"
      height="152px"
      viewBox="0 0 152 152"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path d="M75,61 L75,123" id="path-1" />
        <filter
          x="-1150.0%"
          y="-16.1%"
          width="2400.0%"
          height="138.7%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feMorphology
            radius="5"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dx="0"
            dy="2"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feMorphology
            radius="5"
            operator="erode"
            in="SourceAlpha"
            result="shadowInner"
          />
          <feOffset dx="0" dy="2" in="shadowInner" result="shadowInner" />
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-61.000000, -54.000000)">
          <g id="hint-svg" transform="translate(62.000000, 55.000000)">
            <circle
              id="circle"
              stroke="#000"
              fill="#F3F3F3"
              cx="75"
              cy="75"
              r="75"
            />
            <g id="Path" strokeLinecap="round">
              <use
                fill="#a9a9a9"
                fillOpacity="1"
                filter="url(#filter-2)"
                xlinkHref="#path-1"
              />
              <use stroke="#a9a9a9" strokeWidth="15" xlinkHref="#path-1" />
            </g>
            <circle
              id="Oval"
              stroke="#000000"
              fill="#000000"
              cx="75.5"
              cy="28.5"
              r="7.5"
            />
          </g>
        </g>
      </g>
    </ScSvg>
  );
};
