import styled, { css } from "styled-components"
import { activeTextMixin, disabledTextMixin } from "../utilties/Mixins"
import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Grow } from "@material-ui/core"
import { ScFlexContainer } from "./Flexbox"
import { ScWarningIcon } from "./Icons/Warning"
import { ScInfoIcon } from "./Icons"

//Maps Text Components to the different font sizes available
const fontSizeMap = {
  main: "1.6rem",
  "data-text": "1.3rem",
  subtext: "1.1rem",
}

export const TextLink = styled.span`
  font-family: "Roboto Slab";
  font-size: ${({ fontSize }) =>
    fontSize ? fontSizeMap[fontSize] : fontSizeMap["main"]};
  ${({ theme, disabled }) =>
    disabled ? disabledTextMixin : activeTextMixin(theme.colors.primary.main)};
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors["secondary"].main};
`

export function ScTextLink(props) {
  if (!props.disabled && props.to) {
    return (
      <Link to={props.to}>
        <TextLink {...props}>{props.children}</TextLink>
      </Link>
    )
  } else if (!props.disabled && props.href) {
    return (
      <a href={props.href} target="_blank">
        <TextLink {...props}>{props.children}</TextLink>
      </a>
    )
  } else {
    return <TextLink {...props}>{props.children}</TextLink>
  }
}
export const ScText = styled.p`
  font-size: ${({ fontSize }) =>
    fontSizeMap[fontSize] ? fontSizeMap[fontSize] : fontSizeMap["main"]};
  font-family: ${({ theme }) => theme.typography.main};
  margin: 0;
  word-wrap: break-word;
  line-height: ${({ theme }) => theme.typography.lineHeight};
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors["secondary"].main};
`

export const ScDateText = styled(ScText)`
  color: grey;
`

export const ScInlineText = styled.span.attrs(() => ({
  "data-test-id": props =>
    props["data-test-id"] ? props["data-test-id"] : "text",
}))`
  display: inline-block;
  font-size: ${({ fontSize }) =>
    fontSizeMap[fontSize] ? fontSizeMap[fontSize] : fontSizeMap["main"]};
  font-family: ${({ theme }) => theme.typography.main};
  margin: 0;
  word-wrap: break-word;
  line-height: ${({ theme }) => theme.typography.lineHeight};
`

export const ScBetaText = styled.span`
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: ${({ fontSize }) =>
    fontSizeMap[fontSize]
      ? fontSizeMap[fontSize]
      : fontSizeMap["data-text"]} !important;
  font-family: ${({ theme }) => theme.typography.main};
  margin: 0;
  font-weight: 700;
`

export const ScHintText = props => {
  return (
    <Grow in={true} timeout={300}>
      <ScFlexContainer style={{ paddingTop: "5px" }} alignItems="center">
        <ScInfoIcon />
        <ScText style={{ paddingLeft: "5px", paddingTop: "3px" }} {...props}>
          {props.children}
        </ScText>
      </ScFlexContainer>
    </Grow>
  )
}

export const ScError = styled.span`
  font-size: ${({ fontSize }) =>
    fontSizeMap[fontSize] ? fontSizeMap[fontSize] : fontSizeMap["main"]};
  color: ${({ theme }) => theme.colors.error.main};
  font-family: ${({ theme }) => theme.typography.main};
  display: inline-block;
`
export const ScErrorText = props => {
  return (
    <Grow in={true} timeout={300}>
      <ScFlexContainer style={{ paddingTop: "5px" }} alignItems="center">
        <ScWarningIcon />
        <ScError style={{ paddingLeft: "5px", paddingTop: "3px" }} {...props}>
          {props.children}
        </ScError>
      </ScFlexContainer>
    </Grow>
  )
}

ScErrorText.propTypes = {
  "data-test-id": PropTypes.string,
  theme: PropTypes.object,
  className: PropTypes.string,
  fontSize: PropTypes.string,
}
