import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Dialog } from "@material-ui/core";
import { ScCloseXIcon } from "./Icons/CloseX";

export const ScModalContainer = styled(Dialog).attrs(() => ({
  classes: { paper: "modal-container" },
}))`
.modal-container{
  margin: 0;
  outline:none;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 40px;
  width: ${({ width }) => (width ? width : "auto")};
  border-radius: 10px;
  display:block;
  overflow: ${({ overflow }) => (overflow ? overflow : "auto")};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: ${maxHeight};
      `}
    ${({ minWidth }) =>
      minWidth &&
      css`
        min-width: ${minWidth};
      `}
  background: white;
  position: relative;
}

`;

class ScModalClass extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ScModalContainer {...this.props}>
        {this.props.onClose && <ScCloseXIcon onClick={this.props.onClose} />}
        {this.props.children}
      </ScModalContainer>
    );
  }
}

export const ScModal = React.forwardRef((props, ref) => (
  <ScModalClass {...props} forwardedRef={ref} />
));
