import React, { Component } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { ScText, ScErrorText } from "./Text";

export const ScAccordionHeaderText = styled.h3`
  font-size: 16px;
  color: ${({ color, theme }) => (color ? color : theme.colors.primary.main)};
  & path {
    fill: ${({ color, theme }) => (color ? color : theme.colors.primary.main)};
  }
  font-weight: 700;
  margin: 0;
`;

export const ScAccordionCircle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

export const ScAccordionCircleHorizontal = styled.div`
  position: absolute;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color].main : "currentColor"};
  width: 10px;
  height: 2px;
  left: 50%;
  top: 50%;
  ${({ open }) =>
    open
      ? css`
          transition: all 0.5s ease-in-out;
          transform: translate(-50%, -50%) rotate(90deg);
          opacity: 0;
        `
      : css`
          transition: all 0.5s ease-in-out;
          transform: translate(-50%, -50%) rotate(-90deg);
          opacity: 1;
        `}
`;

export const ScAccordionCircleVertical = styled.div`
  position: absolute;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color].main : "currentColor"};
  width: 2px;
  height: 10px;
  left: 50%;
  top: 50%;
  ${({ open }) =>
    open
      ? css`
          transition: all 0.5s ease-in-out;
          transform: translate(-50%, -50%) rotate(90deg);
        `
      : css`
          transition: all 0.5s ease-in-out;
          transform: translate(-50%, -50%) rotate(-90deg);
        `}
`;
export const ScAccordionContainer = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "auto")};
  margin-top: 0px;
  transform-origin: 0% 0%;
  transition: all 200ms linear;
`;

export const ScAccordionHeader = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "auto")};
  margin: 0px;
  width: 100%;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ open, extraPadding }) =>
    extraPadding &&
    open &&
    css`
      padding-bottom: 20px;
    `}
`;

export const ScAccordionBody = styled.div`
  position: relative;
  z-index: 10;
  padding-top: 10px;
  width: ${({ width }) => (width ? width : "auto")};
  transition: transform 400ms linear;
  opacity: 0;
  transform: ${({ open }) => (open ? "scaleY(1)" : "scaleY(0)")};
  max-height: 0;
  transform: scaleY(0);
  ${({ open }) => {
    return (
      open &&
      css`
        transform: scaleY(1);
        opacity: 1;
        max-height: none;
      `
    );
  }}
`;

export class ScAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      open: this.props.open,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleAccordionOpen = this.handleAccordionOpen.bind(this);
  }

  onFocus() {
    this.setState({
      isFocused: true,
    });
  }
  onBlur() {
    this.setState({
      isFocused: false,
    });
  }
  handleAccordionOpen() {
    this.setState(prev => {
      return { open: !prev.open };
    });
  }
  render() {
    const {
      error,
      style,
      children,
      width,
      header,
      onClick,
      open,
      extraPadding,
    } = this.props;
    const { isFocused } = this.state;
    return (
      <ScAccordionContainer
        style={style}
        width={width}
        isFocused={isFocused}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      >
        <ScAccordionHeader
          onClick={onClick}
          open={open}
          extraPadding={extraPadding}
        >
          <ScAccordionCircle>
            <ScAccordionCircleHorizontal open={open} color="primary" />
            <ScAccordionCircleVertical open={open} color="primary" />
          </ScAccordionCircle>

          {header}
        </ScAccordionHeader>
        <ScAccordionBody open={open}>{children}</ScAccordionBody>

        {error && <ScErrorText>{error}</ScErrorText>}
      </ScAccordionContainer>
    );
  }
}

export const ScAccordionToggleIcon = ({ open, onClick }) => {
  return (
    <ScAccordionCircle onClick={onClick}>
      <ScAccordionCircleHorizontal open={open} />
      <ScAccordionCircleVertical open={open} />
    </ScAccordionCircle>
  );
};

ScAccordion.propTypes = {
  error: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
