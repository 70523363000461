import styled from "styled-components"

export const ScAppContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  display: block;
  margin: 0 auto;
`

export const ScPageWrapper = styled.section`
  padding: 40px;
  padding-top: 90px;
  @media only screen and (max-width: 960px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
  }
`
