import React from "react"
import { bool } from "prop-types"
import { StyledMenu } from "./Menu.styled"
import { ScTextLink } from "../../../StyledComponents/elements"

const Menu = ({ open, setOpen, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <ScTextLink onClick={() => setOpen(!open)} to="/" tabIndex={tabIndex}>
        Home
      </ScTextLink>
      <ScTextLink
        onClick={() => setOpen(!open)}
        to="/about"
        tabIndex={tabIndex}
      >
        About
      </ScTextLink>
      <ScTextLink
        onClick={() => setOpen(!open)}
        to="/portal-in-the-park"
        tabIndex={tabIndex}
      >
        The Portal in the Park
      </ScTextLink>
      <ScTextLink
        onClick={() => setOpen(!open)}
        to="/muklemins"
        tabIndex={tabIndex}
      >
        The Muklemins: Camping In
      </ScTextLink>
      <ScTextLink onClick={() => setOpen(!open)} to="/news" tabIndex={tabIndex}>
        News
      </ScTextLink>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
