import styled from "styled-components";
import React from "react";

export const ScHelpSvg = styled.svg.attrs(() => ({
  xmlns: "http://www.w3.org/2000/svg",
  version: "1.1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 64 80",
  "enable-background": "new 0 0 64 64",
  "data-test-id": "help-svg",
}))`
  height: 18px;
  width: 18px;
  margin-top: -5px;
  padding-left: 5px;
  cursor: pointer;
  path {
    fill: ${({ color }) => (color ? color : "currentColor")};
    line-height: normal;
    font-weight: 700;
  }
`;

export const ScHelpIcon = props => {
  return (
    <ScHelpSvg {...props}>
      <g>
        <path
          fill="#000000"
          d="M9.9,63.7H9.6c-2.5,0-4-0.7-4.6-2c-0.2-0.5-0.7-1.7,0.4-3.1l3.4-4.6c0.4-0.5,0.6-0.8,0.7-1.1   C4,47.1,0.9,39.5,0.9,31.4c0-17.2,14-31.1,31.1-31.1c17.1,0,31.1,14,31.1,31.1c0,17.1-13.9,31.1-31.1,31.1c-3.4,0-6.7-0.5-9.8-1.6   c-0.1,0-0.2,0.1-0.3,0.1c-1.2,0.6-2.4,1-3.5,1.4c-0.4,0.1-0.8,0.2-1.2,0.3l-0.5,0.1C14.7,63.4,12.4,63.7,9.9,63.7z M32,4.7   c-14.7,0-26.7,12-26.7,26.7c0,7.1,2.8,13.8,7.8,18.9l0.3,0.3l0.2,0.3c0.5,1.1,0.6,2.2,0.2,3.4c-0.3,1-0.9,1.7-1.4,2.4l-1.9,2.6   c1.9,0,3.6-0.3,5.1-0.7l0.6-0.2c0.3-0.1,0.6-0.2,0.9-0.3c1-0.3,1.9-0.7,2.9-1.1c0.5-0.3,1.3-0.5,2.2-0.6l0.7,0l0.4,0.1   c2.8,1,5.8,1.5,8.8,1.5c14.7,0,26.7-12,26.7-26.7C58.7,16.7,46.7,4.7,32,4.7z"
        />
        <path
          fill="#000000"
          d="M31.9,49v0.3V49c-0.8,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.2c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3,1.4,3,3.1   C35,47.6,33.6,49,31.9,49z"
        />
        <path
          fill="#000000"
          d="M41.4,27.7c-1.1,1.5-2.6,2.7-4,3.9l-0.3,0.3c-2,1.7-2.2,2.1-2.3,4.7c0,0.7,0,1.3,0,2c0,0.5-0.2,0.6-0.6,0.6   c-0.3,0-0.6,0-0.9,0h-1.1v0h-2.7c-0.5,0-0.6-0.1-0.6-0.6c-0.1-2.1,0.1-4.2,0.5-6.2c0.2-1.1,0.8-2.1,1.9-3.1   c0.8-0.8,1.7-1.6,2.6-2.3c0.4-0.4,0.8-0.7,1.2-1.1l0.1-0.1c0.2-0.2,0.5-0.4,0.7-0.7c1.2-1.3,1-3.2-0.3-4.4c-1-1-2.3-1.3-4.1-1.1   c-2.2,0.3-3.6,1.6-4.2,4.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2c-0.1,0.4-0.4,0.4-0.5,0.4c-1.7-0.2-3.4-0.4-5-0.6   c-0.3,0-0.5-0.2-0.5-0.7c0.3-4.3,3.7-7.8,8.3-8.6c2.7-0.4,5.1-0.3,7.3,0.5c2.9,1,4.9,2.9,5.9,5.6C43.4,23.3,43,25.6,41.4,27.7z"
        />
      </g>
    </ScHelpSvg>
  );
};
