import { css } from "styled-components";
import { disabledColor } from "../utilties/Colors";

export const disabledTextMixin = css`
  cursor: not-allowed;
  pointer-events: none;
  color: ${({ theme }) => theme.colors.disabled.main};
`;

export const activeTextMixin = color => css`
  cursor: pointer;
  color: ${color};
  &:hover {
    text-decoration: underline;
  }
`;

export const disabledButtonMixin = css`
  cursor: not-allowed;
  pointer-events: none;
  background: ${disabledColor};
  border: 1px solid ${disabledColor};
  color: grey;
`;

export const activeButtonMixin = backgroundColor => {
  return css`
    cursor: pointer;
    pointer-events: auto;
    background: ${backgroundColor};
    color: #ffffff;
    border: 1px solid ${backgroundColor};
  `;
};
