//colors shared across all themes
const commonColors = {
  error: { main: "#e20000", hover: "rgba(184, 0, 0, 1)" },
  disabled: { main: "rgba(0, 0, 0, 0.5)", border: "#D3D3D3" },
}

//theme will have the same fonts and image path rooted from the marketer variable
export const baseTheme = {
  typography: {
    headers: "Caveat Brush, sans-serif, Arial",
    main: " sans-serif, Arial, Helvetica",
    lineHeight: "30px",
  },
}

//MAIN*****************************************************
export const mainTheme = {
  name: "main",
  primaryDark: "#0D0C1D",
  primaryLight: "#EFFFFA",
  primaryHover: "#343078",
  mobile: "576px",
  colors: {
    primary: { main: "#00a2bd", hover: "#0095ae" },
    secondary: {
      main: "#113a59",
      hover: "#0b2538",
    },
    third: {
      main: "#ffffff",
      hover: "#ffffff",
    },
    cancel: { main: "#A9A9A9", hover: "rgb(118, 118, 118)" },
    ...commonColors,
  },
}
//MAIN*****************************************************

export const getTheme = () => {
  return { ...mainTheme, ...baseTheme }
}
