import { keyframes } from "styled-components";

export const ScKeyframes = keyframes`
 /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
     100% {
    -webkit-transform: rotate(360deg);
  }
`;

export const ScRotateKeyframes = keyframes`
    0% {
        transform:rotate(0deg)
    }
    100% {
        transform:rotate(360deg)
    }
`;
