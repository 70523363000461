import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "@material-ui/core";

export const ScToolTip = styled(props => (
    <Tooltip
        classes={{ popper: props.className, tooltip: "tooltip" }}
        {...props}
    />
))`
  & .tooltip {
    background-color: black !important;
    color: #fff !important;
    font-size: 13px !important;
    padding: 10px !important;
  }
`;