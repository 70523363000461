import React, { Component } from "react";
import styled from "styled-components";

const sizeMap = {
  small: { width: "20px", height: "20px" },
  medium: { width: "30px", height: "30px" },
  large: { width: "40px", height: "40px" },
};

export const ScGTagOpen = styled.g`
  display: none;
`;

export const ScGTagClosed = styled.g`
  /* display: none; */
`;

export const ScSvg = styled.svg`
  width: ${({ size }) => (size ? sizeMap[size].width : sizeMap["small"].width)};
  min-width: ${({ size }) =>
    size ? sizeMap[size].width : sizeMap["small"].width};
  height: ${({ size }) =>
    size ? sizeMap[size].height : sizeMap["medium"].height};
  fill: ${({ color }) => (color ? color : "grey")};
  cursor: pointer;

  &:hover {
    ${ScGTagOpen} {
      display: inline-block;
      fill: ${({ theme }) => theme.colors.primary.main};
    }
    ${ScGTagClosed} {
      display: none;
    }
  }
`;

export class ScTrashIcon extends Component {
  render() {
    return (
      <ScSvg
        {...this.props}
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 25 24.8"
        // style="enable-background:new 0 0 25 24.8;"
        // xml:space="preserve"
        data-ember-action=""
        data-ember-action-1015="1015"
      >
        <ScGTagOpen>
          <path d="M18.7,24.4H5.9L4.9,7h14.9L18.7,24.4z M7.6,22.6H17l0.8-13.7h-11L7.6,22.6z" />
          <polygon points="13.6,10.3 13.1,21.2 14.9,21.2 15.4,10.3 " />
          <polygon points="11.5,21.2 11,10.3 9.2,10.3 9.7,21.2 " />
          <path
            d="M19.1,0.7l-4.7,0.9l-0.8-1.4L8.2,1.3L8,3l-4.7,1l0.2,4.7l17.3-3.5L19.1,0.7z 
             
             M8.8,1.9l4.4 -1.0 l0.5,0.8
             L8.7,2.8z 
             
             M5.2,6.4l0-1L18,2.8l0.3,0.9L5.2,6.4z"
          />
        </ScGTagOpen>
        <ScGTagClosed>
          <path
            d="M6.8,8.8h11L17,22.6
             H7.6L6.8,8.8z 
             M4.9,7l1,17.4h12.8
             l1-17.4
             H4.9z"
          />
          <polygon points="13.6,10.3 13.1,21.2 14.9,21.2 15.4,10.3 " />
          <polygon points="11.5,21.2 11,10.3 9.2,10.3 9.7,21.2 " />
          <path
            d="M20.4,4h-4.8l-0.5-1.6
             H9.5L9,4
             H4.2
             L3.5,8.6h17.6
             L20.4,4z 
             
             M9.9,3.2h4.8
             L14.9,3.9h-5.2z
             
             M5.6,6.7l0.2-1 h13l0.2,1
             H5.6z"
          />
        </ScGTagClosed>
      </ScSvg>
    );
  }
}
