// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-author-js": () => import("../src/pages/add-author.js" /* webpackChunkName: "component---src-pages-add-author-js" */),
  "component---src-pages-add-books-js": () => import("../src/pages/add-books.js" /* webpackChunkName: "component---src-pages-add-books-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-muklemins-js": () => import("../src/pages/muklemins.js" /* webpackChunkName: "component---src-pages-muklemins-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portal-in-the-park-js": () => import("../src/pages/portal-in-the-park.js" /* webpackChunkName: "component---src-pages-portal-in-the-park-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

