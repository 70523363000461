import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";

export const ScLinearProgressBar = styled(LinearProgress)`
  background-color: ${({ theme }) => theme.colors.primary.main} !important;
  padding: 6px;
  border-radius: 10px;
  > div {
    background: ${({ theme }) => theme.colors.primary.hover} !important;
  }
`;
