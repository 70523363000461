import styled from "styled-components"
import PropTypes from "prop-types"

export const ScH1 = styled.h1.attrs(props => {
  return {
    "data-test-id": props["data-test-id"] ? props["data-test-id"] : "h1",
  }
})`
  font-size: 3.2rem;
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors["secondary"].main};
  font-family: ${({ theme }) => theme.typography.headers};
  margin: 0px;
  padding: 0px;
  font-weight: ${({ fontWeight }) => (fontWeight ? 400 : 700)};
  text-align: left;
`

ScH1.propTypes = {
  "data-test-id": PropTypes.string,
  theme: PropTypes.object,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
}

export const ScH2 = styled.h2.attrs(props => {
  return {
    "data-test-id": props["data-test-id"] ? props["data-test-id"] : "h2",
  }
})`
  font-size: 2.4rem;
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors["secondary"].main};
  font-family: ${({ theme }) => theme.typography.headers};
  margin: 0px;
  padding: 0px;
  font-weight: ${({ fontWeight }) => (fontWeight ? 400 : 700)};
  text-align: left;
`

ScH2.propTypes = {
  "data-test-id": PropTypes.string,
  theme: PropTypes.object,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
}

export const ScH3 = styled.h3.attrs(props => {
  return {
    "data-test-id": props["data-test-id"] ? props["data-test-id"] : "h3",
  }
})`
  font-size: 2rem;
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors["secondary"].main};
  font-family: ${({ theme }) => theme.typography.headers};
  font-weight: ${({ fontWeight }) => (fontWeight ? 400 : 700)};
  margin: 0px;
  padding: 0px;
  text-align: left;
`

ScH3.propTypes = {
  "data-test-id": PropTypes.string,
  theme: PropTypes.object,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
}

export const ScH4 = styled.h4.attrs(props => {
  return {
    "data-test-id": props["data-test-id"] ? props["data-test-id"] : "h4",
  }
})`
  font-size: 1.6rem;
  color: ${({ theme, color }) =>
    color ? theme.colors[color].main : theme.colors["secondary"].main};
  font-family: ${({ theme }) => theme.typography.headers};
  font-weight: ${({ fontWeight }) => (fontWeight ? 400 : 700)};
  margin: 0px;
  padding: 0px;
  text-align: left;
`

ScH4.propTypes = {
  "data-test-id": PropTypes.string,
  theme: PropTypes.object,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
}
