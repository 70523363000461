import styled, { css } from "styled-components";

export const ScCard = styled.div`
  margin: 0 auto;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 40px;
  width: ${({ width }) => (width ? width : "auto")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "100%")};
  transition: all 200ms linear;
  border-radius: 10px;

    ${({ minWidth }) =>
      minWidth &&
      css`
        min-width: ${minWidth};
      `}

  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}

    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}
`;

export const ScPageCard = styled(ScCard)`
  height: 85vh;
  overflow: ${({ overflow }) => (overflow ? overflow : "auto")};
`;

export const ScGraphCard = styled(ScCard)`
  margin-bottom: 15px;
  ${({ minHeight }) =>
    minHeight
      ? css`
          min-height: ${minHeight};
        `
      : css`
          min-height: 600px;
        `};
`;

export const ScGraphHeaderContainer = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
