import React, { Component } from "react"
import styled from "styled-components"
import { ScText } from "../StyledComponents/elements"
const ScFooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding: 20px;
  text-align: center;
`

class Footer extends Component {
  render() {
    return (
      <ScFooterContainer>
        <ScText color="third" fontSize="subtext">
          &copy; 2019 Cricket Casey
        </ScText>
      </ScFooterContainer>
    )
  }
}

export default Footer
