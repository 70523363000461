import styled, { css } from "styled-components"
import { disabledButtonMixin, activeButtonMixin } from "../utilties/Mixins"
import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

const buttonSizes = {
  small: css`
    font-size: 11px;
    padding: 6px 12px;
  `,
  medium: css`
    font-size: 14px;
    padding: 8px 16px;
  `,
  large: css`
    font-size: 16px;
    padding: 12px 24px;
  `,
}

//Submission button for forms
export const ScInputButton = styled.input.attrs(() => {
  return { type: "submit" }
})`
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  border-radius: 4px;
  display: inline-block;
  font-family: ${({ theme }) => theme.typography.main};
  text-decoration: none;
  ${({ theme, color, disabled }) =>
    disabled
      ? disabledButtonMixin
      : activeButtonMixin(theme.colors[color].main)};
  ${({ size }) => (size ? buttonSizes[size] : buttonSizes["medium"])}
  transition: all 200ms linear;
  &:hover {
    position: relative;
    transform: translateY(-1px);
    background: ${({ color, theme }) => theme.colors[color].hover};
  }
  &:active {
    position: relative;
    transform: translateY(2px);
  }
  &:focus {
    outline: none;
  }
`

export const ScButton = styled.button`
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  border-radius: 4px;
  display: inline-block;
  font-family: ${({ theme }) => theme.typography.main};
  text-decoration: none;
  ${({ theme, color, disabled }) =>
    disabled
      ? disabledButtonMixin
      : activeButtonMixin(theme.colors[color].main)};
  ${({ size }) => (size ? buttonSizes[size] : buttonSizes["medium"])}
  transition: all 200ms linear;
  &:hover {
    position: relative;
    transform: translateY(-1px);
    background: ${({ color, theme }) => theme.colors[color].hover};
  }
  &:active {
    position: relative;
    transform: translateY(2px);
  }
  &:focus {
    outline: none;
  }
  ${({ arrow }) => {
    if (arrow == "before") {
      return css`
        &:before {
          content: "\\25c4";
          padding-right: 0.5em;
        }
      `
    }
    if (arrow == "after") {
      return css`
        &:after {
          content: "\\25ba";
          padding-left: 0.5em;
        }
      `
    }
  }}
`

export const ScNavButton = styled(ScButton)`
  color: white;
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.typography.headers};
  ${({ dropdown }) =>
    dropdown &&
    css`
      &:after {
        border: solid currentColor;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
      }
    `};
`

export function ScLinkButton(props) {
  return !props.disabled && props.to ? (
    <Link to={props.to}>
      <ScButton {...props}>{props.children}</ScButton>
    </Link>
  ) : (
    <ScButton {...props}>{props.children}</ScButton>
  )
}

export function ScNavLinkButton(props) {
  return !props.disabled && props.to ? (
    <Link to={props.to}>
      <ScNavButton {...props}>{props.children}</ScNavButton>
    </Link>
  ) : (
    <ScNavButton {...props}>{props.children}</ScNavButton>
  )
}

export const ScSmallButton = styled(ScButton)`
  font-size: 13px;
  padding: 6px 8px;
`

export function ScExternalLinkButton(props) {
  return !props.disabled ? (
    <a target="__blank" href={props.href}>
      <ScButton {...props}>{props.children}</ScButton>
    </a>
  ) : (
    <ScButton {...props}>{props.children}</ScButton>
  )
}

ScLinkButton.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
