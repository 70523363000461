import styled from "styled-components";
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

export function ScImageLink(props) {
  return !props.disabled && props.to ? (
    <Link to={props.to}>
      <ScImage {...props}>{props.children}</ScImage>
    </Link>
  ) : (
      <ScImage {...props}>{props.children}</ScImage>
    );
}

ScImageLink.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  verticalAlign: PropTypes.string,
  disabled: PropTypes.bool,
  imageSrc: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export const ScImage = styled.img.attrs(props => {
  return {
    src: props.theme.imagePath + props.imageSrc,
    "data-test-id": "image",
  };
})`
  width: ${({ width }) => (width ? width : "auto")};
  height: ${({ height }) => (height ? height : "auto")};
  vertical-align: ${({ verticalAlign }) =>
    verticalAlign ? verticalAlign : "baseline"};
`;

ScImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  verticalAlign: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
};
