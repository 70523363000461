import styled, { css } from 'styled-components';

export const ScFlexContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'stretch')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
`;

export const ScFlexItem = styled.div`
  flex-grow: ${({ flexGrow }) => (flexGrow ? flexGrow : 0)};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'none')};
`;
