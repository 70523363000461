/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FirebaseContext, useAuth } from "./Firebase"
import Header from "./header"
import "./layout.css"
import { ThemeProvider } from "styled-components"
import { getTheme } from "./StyledComponents/themes"
import Footer from "./common/Footer"
import { Burger, Menu } from "./common/HamburgerMenu"
import {
  ScAppContainer,
  ScPageWrapper,
} from "./StyledComponents/elements/Wrappers"

import FocusLock from "react-focus-lock"

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener("mousedown", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [ref, handler])
}

const Layout = ({ children }) => {
  const { user, firebase, loading } = useAuth()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [open, setOpen] = useState(false)
  const node = useRef()
  const menuId = "main-menu"

  useOnClickOutside(node, () => setOpen(false))

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <ThemeProvider theme={getTheme()}>
        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
        <ScAppContainer>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>

          <Footer />
        </ScAppContainer>
      </ThemeProvider>
    </FirebaseContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
