import React, { Component } from "react"
import styled from "styled-components"
import { ScTextLink } from "../StyledComponents/elements"

const ScNavbarContainer = styled.nav`
  background-color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  justify-content: space-around;
  padding: 10px 10px;

  a {
    text-decoration: none;
  }

  a.underlined {
    text-decoration: underline;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

class NavBar extends Component {
  render() {
    return (
      <ScNavbarContainer>
        <ScTextLink to="/" fontSize="data-text" color="third">
          Home
        </ScTextLink>
        <ScTextLink to="/about" fontSize="data-text" color="third">
          About Cricket
        </ScTextLink>
        <ScTextLink to="/portal-in-the-park" fontSize="data-text" color="third">
          The Portal in the Park
        </ScTextLink>
        <ScTextLink to="/muklemins" fontSize="data-text" color="third">
          The Muklemins: Camping In
        </ScTextLink>
        <ScTextLink to="/news" fontSize="data-text" color="third">
          News
        </ScTextLink>
      </ScNavbarContainer>
    )
  }
}

export default NavBar
