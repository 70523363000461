import styled, { css } from "styled-components"

export const ScGridRow = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignItems }) =>
    alignItems
      ? css`
          align-items: ${alignItems};
        `
      : css`
          align-items: flex-start;
        `}
  /* flex-flow: row; */
  padding: 0px 0px 50px 0px;
  ${({ extraPadding }) =>
    extraPadding &&
    css`
      padding: 0px 0px 20px 0px;
    `}
  > div:first-child {
    padding-left: 0;
  }
  > div:last-child {
    padding-right: 0;
  }
  ${({ wrap }) => {
    return (
      wrap &&
      css`
        flex-wrap: wrap;
       > div:nth-child(n + ${wrap + 1}) {
          margintop: 30px;
        }
        /* ${ScGridCol} {
          margin-top: 20px;
        } */
        & > div:nth-child(${wrap + "n"}+${wrap + 1}) {
          padding-left: 0;
        }
      `
    )
  }}

  @media only screen and (max-width: 960px) {
    /* flex-wrap: no-wrap; */
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-bottom: 10px;

    button {
      margin-bottom: 10px;
      width: 150px;
    }
  }
`

export const ScWaterfallRow = styled(ScGridRow)`
  ${({ wrap }) => {
    return (
      wrap &&
      css`
        > div:nth-child(n + ${wrap + 1}) {
          margin-top: 0px;
        }
      `
    )
  }}
`

export const ScGridCol = styled.div`
  flex: ${({ span }) => (span ? `0 0 ` + (100 / 12) * span + `%` : "0 0 auto")};
  padding-left: 15px;
  max-width: ${({ span }) => (span ? (100 / 12) * span + `%` : "100%")};
  ${({ flex }) => {
    return (
      flex &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
      `
    )
  }}
  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
  @media only screen and (max-width: 800px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
`

export const ScButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & > div:not(:last-child),
  & > button:not(:last-child),
  & > a:not(:last-child) {
    margin-right: 20px;
  }
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignItems }) =>
    alignItems
      ? css`
          align-items: ${alignItems};
        `
      : css`
          align-items: flex-start;
        `}
`
