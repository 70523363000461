import React, { Component } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { ScFlexContainer } from "./Flexbox";

export const ScTableItem = styled.div`
  /* border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9; */
  font-size: 13px;
  word-wrap: break-word;
  padding: 0px 5px;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ width }) =>
    width &&
    css`
      &&& {
        width: ${width};
      }
    `}
  ${({ align }) => {
    if (align === "center") {
      return css`
        display: flex;
        justify-content: center;
        align-items: center;
      `;
    }
  }}
    ${({ number }) => {
      if (number) {
        return css`
          display: flex;
          justify-content: flex-end !important;
          align-items: center;
          text-align: right !important;
        `;
      }
    }}
    &::after {
    content: "";
    flex: 0 0 12px;
  }
  @media only screen and (max-width: 800px) {
    width: 100% !important;
    text-align: center !important;
  }
`;

export const ScTableRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 15px;
  align-items: center;
  border-bottom: solid 1px rgb(128, 128, 128);
  transition: 0.5s;
  &:first-of-type {
    /* border-top: solid 1px #1565c0;
    border-left: solid 1px #1565c0; */
  }

  &:hover {
    &:not(:first-child) {
      background: #f4f2f1;
    }
  }
  &:hover {
    /* background: #f5f5f5; */
    transition: 500ms;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
  }
`;
export const ScTableHeaderRow = styled(ScTableRow)`
  background-color: ${({ theme }) => theme.colors.primary.main};

  &:first-of-type ${ScTableItem} {
    color: white;
    /* border-color: #1565c0; */
  }
  align-items: center;
  & ${ScTableItem}:first-child {
    border-radius: 20px 0 0 0;
  }
  & ${ScTableItem}:last-child {
    border-radius: 0 20px 0 0;
  }
  & ${ScTableItem} {
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
    font-size: 16px;
    word-break: break-word;
  }
`;
export const ScTableFooterRow = styled(ScTableRow)`
  background-color: #f4f2f1;
  font-weight: 700;
  & ${ScTableItem} {
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
    font-size: 16px;
  }
`;
export const ScTableSummaryRow = styled(ScTableRow)`
  background-color: #f4f2f1;
  font-weight: 700;

  & ${ScTableItem} {
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
    font-size: 16px;
  }
`;
export const ScTable = styled.div`
  width: 100%;
  max-width:100%;
  border-radius: 7px;

  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `}
  ${({ virtualized }) =>
    virtualized &&
    css`
      box-shadow: none !important;
      ${ScTableHeaderRow} {
        & ${ScTableItem}:first-child {
          justify-content: flex-start;
        }
        ${ScTableItem} {
          display: flex;
          align-items: center;
        }
      }
      ${ScTableRow} {
        padding: 0px 10px;
        @media only screen and (max-width: 1400px) {
          ${ScTableItem} {
            font-size: 11px;
          }
        }
      }
      ${ScTableHeaderRow} {
        padding: 15px;
        ${ScTableItem} {
          font-size: 16px;
          word-break: keep-all;
          white-space: normal;
        }
      }
    `}
  & .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    outline: none !important;
  }
  ${({ maxHeight }) => {
    return (
      maxHeight &&
      css`
        max-height: ${maxHeight};
      `
    );
  }}
  & ${ScTableItem} {
    width: ${({ columns }) => (columns ? 100 / columns + "%" : "100%")};
  }


  ${({ firstColumn, columns }) => {
    return (
      firstColumn &&
      css`
        & ${ScTableItem} {
          width: ${(100 - firstColumn) / (columns - 1) + "%"};
        }
        & ${ScTableItem}:first-child {
          width: ${firstColumn + "%"};
        }
      `
    );
  }}

  & ${ScTableItem}:first-child {
    ${({ firstColumnAlign }) => {
      return firstColumnAlign
        ? css`
            text-align: ${firstColumnAlign};
            ${ScFlexContainer} {
              text-align: ${firstColumnAlign};
            }
          `
        : css`
            text-align: left;
            ${ScFlexContainer} {
              text-align: ${firstColumnAlign};
            }
          `;
    }}
    
    
  }
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  & ${ScTableItem} {
    text-align: ${({ textAlign }) => (textAlign ? textAlign : "right")};
  }
  ${({ textAlign }) => {
    if (textAlign === "left") {
      return css`
        & ${ScTableItem} {
          justify-content: flex-start;
          ${ScFlexContainer} {
            justify-content: flex-start;
          }
        }
      `;
    }
    if (textAlign === "center") {
      return css`
        & ${ScTableItem} {
          justify-content: center;
          ${ScFlexContainer} {
            justify-content: center;
          }
        }
      `;
    }
  }}
  & ${ScTableHeaderRow} {
    border-radius: 7px 7px 0 0;
  }
  /* & ${ScTableRow}:nth-child(odd):not(:first-child):not(:last-child) { */
    /* background: green; */
    /* border-bottom: solid 10px black; */
  /* } */
  /* & ${ScTableRow} {
    border-bottom: solid 2px grey;
  } */

  & ${ScTableFooterRow} {
    border-radius: 0 0 7px 7px;
  }
`;

//// For smaller screens
// @media only screen and (max-width: ($breakpoint + 1)){
//     .tablex {
//       flex-direction: column;
//       flex-wrap: no-wrap;
//       .tablex__item{
//         &.tablex__item--foot{
//           margin-bottom: 1rem;
//         }
//       }

//       // Bordered table
//       &.tablex--bordered{
//         &.tablex--rows .tablex__item.tablex__item--foot{
//           border-bottom: none;
//         }
//       }
//     }
//   }

//   // For bigger screens
//   @media only screen and (min-width: $breakpoint){
//     // Flex direction and wrapping
//     .tablex {
//       flex-direction: row;
//       flex-wrap: wrap;

//       // For column layouts alone
//       &.tablex--columns{
//         .tablex__item {
//           &.tablex__item--head{order: -1;}
//           &.tablex__item--foot{order: 1;}
//         }
//       }

//       // Bordered table
//       &.tablex--bordered{
//         &.tablex--rows {
//           border-top: solid 1px #121212;
//         }
//       }

//       // Set different data-columns to be defined from HTML
//       @for $i from 1 to 11 {
//         &[data-columns="#{$i}"]{
//           .tablex__item{
//             flex-basis: $width/$i;
//             width: $width/$i;
//           }
//         }
//       }
//     }
//   }
