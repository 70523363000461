const firebaseConfig = {
  apiKey: "AIzaSyBOWn7hXJpfsR9ZynBDYmqeUngVgz0ahXU",
  authDomain: "cricket-casey-site.firebaseapp.com",
  databaseURL: "https://cricket-casey-site.firebaseio.com",
  projectId: "cricket-casey-site",
  storageBucket: "cricket-casey-site.appspot.com",
  messagingSenderId: "808953778097",
  appId: "1:808953778097:web:27317061e702dc6d5a7790",
  measurementId: "G-WR6GB45C2P",
}

export default firebaseConfig
