import React, { Component } from "react";
import styled, { keyframes, css } from "styled-components";
import { ScH1 } from "./Headings";
import { ScRotateKeyframes } from "../animations/rotate.js";
import { Grow } from "@material-ui/core";

const loaderSizeMap = {
  small: css`
    padding: 10px;
    border: 4px solid #ccc;
  `,
  medium: css`
    padding: 15px;
    border: 6px solid #ccc;
  `,
  large: css`
    padding: 20px;
    border: 10px solid #ccc;
  `,
};

export const ScLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary.main};
  padding: 20px;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `}
`;

export const ScSpinningLoader = styled.div`
  height: 0;
  width: 0;
  margin-left: 10px;

  border-radius: 50%;
  animation: ${ScRotateKeyframes} 1s infinite linear;
  ${({ size }) => (size ? loaderSizeMap[size] : loaderSizeMap.medium)}
  border-right-color: ${({ theme }) => theme.colors.primary.main};
`;

const ScLoadingHeader = styled(ScH1)`
  color: #a9a9a9;
`;

export class ScLoadingSpinner extends Component {
  render() {
    return (
      <Grow in={true} timeout={300}>
        <ScLoadingWrapper
          minHeight={this.props.minHeight}
          style={this.props.style}
        >
          {!this.props.spinnerOnly && (
            <ScLoadingHeader>
              {this.props.children ? this.props.children : "Loading..."}
            </ScLoadingHeader>
          )}
          <ScSpinningLoader size={this.props.size} />
        </ScLoadingWrapper>
      </Grow>
    );
  }
}
